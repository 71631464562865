import React, { Component } from 'react';
import './App.scss';
import './switch.css';
import 'bootstrap/dist/css/bootstrap.css';
import Popup from "reactjs-popup";

import classNames from 'classnames';

import {Button, Grid, TextField, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {Dots} from 'react-preloaders';
import {TestString} from './consts';

import Output from './output';

import $ from 'jquery';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    color: 'black'
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  root:{      
    color: 'purple'    
  }
});

class App extends Component {

  constructor(props){
    super(props)
    let args = argparse()
    const urlmode = (args.uri && args.uri.length>0)
    const isMobile = this.mobileAndTabletcheck(navigator.userAgent||navigator.vendor||window.opera)

    this.state = {
      translationType: 'braille',
      textToTranslate: urlmode ? args.uri : TestString,
      translateInitial: urlmode,
      isMobile: isMobile,
      theme: 'light',
      translation: {
        human: '',
        liblouis: '',
        ml: '',
        ml_score : null
      },
      wordCompletions: {},
      level: 0,
			maxLevels: 4,
			inputFocus: false
    }
    if(isMobile){
	    this.onTouchStart = this.onTouchStart.bind(this);
	    this.onTouchMove = this.onTouchMove.bind(this);
	    this.onTouchEnd = this.onTouchEnd.bind(this);
	
	    this.state.swiped = false;
	    this.swipe = {};
	    this.minDistance = 50;
	    this.time = (new Date).getTime();
		}
  }

  componentDidMount(){
	  //DOT.
    /*const script = document.createElement("script");
    script.src = "http://localhost:3000/lib/dot_avatar/js/app.js";
    script.async = true;
		document.body.appendChild(script);
		*/
		
	  
		setTimeout(function(){
			$('#score').appendTo('.row-navigation .col-avatar').addClass('show');
			window.dispatchEvent(new Event('resize'));
			window.waitForIdle();
		},300);
		
		window.addEventListener('DOMContentLoaded', function () {
	
			$('.btn-copy,.btn-edit').click(function(){
				if(!$(this).hasClass('active')) {
					var owid = $(this).data('window-id');
					$(this).addClass('show');
					$('#'+owid).addClass('show');
					setTimeout(function(){
						$('.output-window').removeClass('show');
						$('.output-window-button').removeClass('show');
					}, 2000);
				}
      });

		});
  }
  
  mobileAndTabletcheck(a) {
	  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) return true
	  return false
	}
	
  handleTranslationTypeChange = event =>{
    this.setState({
      "translationType": event.target.value
    })
  }

  onThemeChange(toTheme){
    this.setState({
      theme: toTheme
    })
  }
  
  onTouchStart(e) {
    const touch = e.touches[0];
    this.swipe = { x: touch.clientX, y: touch.clientY };
    this.setState({ swiped: false });
	  this.time = (new Date).getTime();
  }

  onTouchMove(e) {
    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0];
      this.swipe.swiping = true;
    }
    e.preventDefault();
  }

  onTouchEnd(e) {
	  const currentTime = (new Date).getTime();

	  if(currentTime >= (this.time + 500)) return;
    const touch = e.changedTouches[0];
    const absX = Math.abs(touch.clientX - this.swipe.x);
    const absY = Math.abs(touch.clientY - this.swipe.y);
    let directionX = (touch.clientX - this.swipe.x ) < 0 ? 1 : -1;
    let directionY = (touch.clientY - this.swipe.y) < 0 ? 1 : -1;
    let curLevel = this.state.level;
    const maxLevels = this.state.maxLevels;

    if (this.swipe.swiping && absY > this.minDistance ) {

			const factor = Math.floor(absY / this.minDistance); 
			
		  directionY *= factor;
		  curLevel += directionY 
		  if(curLevel > maxLevels) curLevel = maxLevels;
		  if(curLevel < 0)curLevel = 0;

	    this.setState({
		    level: curLevel 
	    })
	    console.log('level'+(curLevel + directionY));

    
      this.props.onSwiped && this.props.onSwiped();
      this.setState({ swiped: true });
      console.log('swiped')

    }
    this.swipe = {};
  }
	handleFocus (e) {
	  this.setState({
		  inputFocus: true
	  })
	}
	handleBlur (e) {
	  this.setState({
		  inputFocus: false
	  })
	}

  render() {
    const textToTranslate = this.state.textToTranslate;
    let mlTextHelper = "";
    let hasStats = this.state.translation && this.state.translation.ml_score !== null   

    let translation = this.state.translation
    let translation_id = translation ? translation.id : 0
    let theme = this.state.theme
    let level = this.state.level
    let isMobile = this.state.isMobile
    const inputFocus = this.state.inputFocus
		
		const props = {};
		if(isMobile){
			props.onTouchStart = this.onTouchStart;
			props.onTouchMove = this.onTouchMove;
			props.onTouchEnd = this.onTouchEnd;
		}
		
    return (
      <div className={"container-fluid themed " + theme+ " level-"+level+ (inputFocus ? " input-focus":"")} style={{flexGrow: 1 }}
       	{...props}
        >
          <Output 
	          translation={translation} 
	          text={textToTranslate} 
	          key={translation_id}
	          onThemeChange={this.onThemeChange.bind(this)}
	          translateInitial={this.state.translateInitial}
	          isMobile={this.state.isMobile}          
	          onFocus={this.handleFocus.bind(this)}
	          onBlur={this.handleBlur.bind(this)}>
	        </Output>        
      </div>
    );
  }
}

export default withStyles(styles)(App);


function argparse(url) {
  if(!url) url = window.location.search;
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

var observe;
if (window.attachEvent) {
    observe = function (element, event, handler) {
        element.attachEvent('on'+event, handler);
    };
}
else {
    observe = function (element, event, handler) {
        element.addEventListener(event, handler, false);
    };
}