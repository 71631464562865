
import React, { Component, useRef } from 'react'
import {Button, Grid, TextField, Select, MenuItem, FormControl, InputLabel} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {Dots} from 'react-preloaders'
import { Container, Row, Col } from 'reactstrap'
import PropTypes from "prop-types"
import SpeechRecognition from "react-speech-recognition"

import { apiservice } from './apiservice'

import Switch from './switch'
import Word from './word'
import Input from './input'
import {ApiEndpoint} from './consts'
import {TranslatorURL} from './consts'
import './preloader.css'
import TextareaAutosize from "react-textarea-autosize"
import ReactTooltip from 'react-tooltip'

const speed = 80
const ticks_per_word = 8
let speechToTextOptions = {
  autoStart: false,
  //continuous: false, stop if the user stops talking
}


function hasBraille(text){
  let hb = false;
  text = text.trimStart()
  for(let i=0; i<text.length; i++){
    let ch = text[i];
    if(ch==' ') break;
    let code = ch.charCodeAt(0)    
    //Start 0x2800 - utf-16 
    //end   0x28FF - utf-16
    if(code >= 0x2800 && code <= 0x28FF){ 
      hb = true;
    }
  }
  return hb;
}


const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      color: 'black'
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
    root:{      
      color: 'purple'    
    }
  });

const propTypes = {
    // Props injected by SpeechRecognition
    transcript: PropTypes.string,
    resetTranscript: PropTypes.func,
    browserSupportsSpeechRecognition: PropTypes.bool
};

class Output extends Component {
    
    constructor(props){
        super(props)
        this.props = props
        this.state = {
          inputKey: 0,
          languageMode: 'braille',
          text: props.text,
          files: [],
          ticki: 0,
          time: null,
          paused: false,
          show_all_output: false,
          is_complete: false,
          theme: 'light',
          developmentMode: false,
          asciiMode: false,
          menuOpen: false,
          loading: false,
          inputType: 'text',
          translation: {
            liblouis: '',
            ml: '',
            human: ''
          },
          //Used if we're using a different preview of the translation, ASCII braille for example
          translationView: null,
          isRecording: false,
          keyboardEnabled: false,
          isText: true,
          isUpload: false,
          editEnabled: false,
          firstSelect: true,
          loadingLoading: false,
          loadingShow: false
        }
        this.onWordOp = props.onWordOp
        if(this.props.translateInitial){
          this.state.loading = true
        }

        if (!props.browserSupportsSpeechRecognition || this.props.isMobile) {
          //alert("Your browser doesn't support speech recognition.")
          this.state.removeSpeech = true
        }
        this.toggleDevSwitch = React.createRef();
        this.toggleAscSwitch = React.createRef();
        this.textInputField = React.createRef(); 
    }
		setLoading(){
      this.setState({loadingShow : true });
      
    	setTimeout((function(){
      	this.setState({loadingLoading: true });
      }).bind(this), 300)

    	setTimeout((function(){
        this.setState({loadingShow: false })
      }).bind(this), 2000)
    	setTimeout((function(){
        this.setState({loadingLoading: false })
      }).bind(this), 2300)
		}
    toggleDevelopmentMode(_switch){
      this.setState({
        developmentMode: !this.state.developmentMode
      })
      //if(!this.state.developmentMode){
	    this.setState(this.getCleanState())
      //}
			//shut off ascii mode
      if(!this.state.developmentMode && this.state.asciiMode){
	      this.setState({ asciiMode: !this.state.asciiMode }) 
      	this.toggleAscSwitch.current.setState({ isChecked: false})
      	setTimeout((function(){
	        this.translate()
	      }).bind(this), 100)
      }
    }

    toggleAscii(_switch){
      this.setState({
        asciiMode: !this.state.asciiMode
      })
      
      //shut off editmode
      if(!this.state.asciiMode && this.state.editEnabled){
		    this.setState({
		      editEnabled: false
		    })
			}
      
			//shut off developer mode
		  if(!this.state.asciiMode && this.state.developmentMode){			  
	      this.setState({ developmentMode: !this.state.developmentMode })
		    this.toggleDevSwitch.current.setState({ isChecked: false})
			}
      setTimeout((function(){
        this.translate()
      }).bind(this), 100)
    }

    toggleMenu(){
      this.setState({
        menuOpen: !this.state.menuOpen
      })
    }
    closeMenu(){
      this.setState({
        menuOpen: false
      })
    }
    
    toggleLanguageModes(){
      let currentMode = this.state.languageMode;
      let targetMode = '';
      if(currentMode == 'braille'){
        targetMode = 'english'
      }else{
        targetMode = 'braille'
      }
      var translatedText = this.state.translation ? this.state.translation.ml : '';
      var inputText = this.state.text;
      if(translatedText != ''){
        this.setState({
          languageMode: targetMode,
          text: translatedText,
          inputKey: this.state.inputKey+1
        })
        setTimeout((()=>{
          this.translate()
        }).bind(this), 15)
      }else{
        this.setState({
          languageMode: targetMode
        })
      }
    }
		fileUploadStart(){
			this.setLoading();
		}
    getCleanState(){
      return {
        ticki: 0,
        paused: false,
        is_complete: false,
        time: null,
        show_all_output: false,
        word_completions: {},
        translationView: null,
        //isRecording: false
      }
    }

    componentDidMount() {
      if(this.props.translateInitial) this.setLoading();
      this.interval = setInterval(() => 
      {
        if(this.state.loading){
          return
        }
        let t = this.state.translation
        if(!t) return
        let toks = t.tokens
        if(!toks) return
        if(this.state.paused) return
        let tok_count = Object.keys(toks).length
        let max_ticks = tok_count * ticks_per_word
        if(max_ticks==this.state.ticki){
          // this.setState({
          //   paused: true,
          //   ticki:0,
          //   show_all_output: true,
          //   is_complete: true
          // })
          return
        }
        //console.log(this.state.ticki)
        const nextTick = this.state.ticki + 1
        let completions = {}
        for(let i=0; i<tok_count; i++){
          completions[i] = {
            index: i,
            completion: this.getCompletionForWord(i, nextTick),
            token: toks[i]
          }
        }
        this.setState({
          time: Date.now(),
          ticki : nextTick,
          show_all_output: false,
          is_complete: false,
          word_completions: completions
         })
      }, speed);
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }
    
    displayTranslation(){
      let loading = this.state.loading
      let t = this.state.translation
      let {liblouis, human, ml} = t
      
      if(loading){
      }else{
        let hasStats = t!=undefined && t.stats!=undefined
        let score = hasStats ? (t.stats.score.raw * 100).toFixed(2) : 0
        let scoreCorrected = hasStats ? (t.stats.score.corrected * 100).toFixed(2) : 0
        if(scoreCorrected==0) scoreCorrected = 100
        if(score==0) score = 100
        return (
          <div>
            <div className="translation dot" margin="normal">
              {this.displayWords()}
              <h4 className="hide-on-debug"><strong>DOT Translation</strong></h4>
              <div className="liblouis hide-on-debug" margin="normal">
	              {this.displayStaticTokens("liblouis")}
	            </div>
              <h4 className="hide-on-debug"><strong>Liblouis</strong></h4>
            </div>
            
            <div className="hide-on-debug">
            		{        
                  (hasStats) && 
                  (<div>
                      Score in relation to Liblouis <b>{scoreCorrected}</b><br></br>
                    </div>)
                }
            </div>
          </div>
        )
      }
    }

    concatTokens(){
      let output = ""
      let t = this.state.translation
      if(!t) return output
      let toks = t.tokens
      if(!toks) return output
      for(let i=0; i< Object.keys(toks).length; i++){
        let tok = toks[i]
        output += tok.prefix + tok.t
      }
      return output
    }

    getCompletionForWord(word_ix, tickValue=null){
      const tick = tickValue!== null ? tickValue : this.state.ticki
      let complete = this.state.is_complete
      const word_tick_start = word_ix*ticks_per_word
      const word_tick_end = (word_ix+1)*ticks_per_word
      let completion = 0
      if(tick>=word_tick_end) completion = 100
      else{
        completion = ((tick - word_tick_start) / ticks_per_word) * 100
      }
      if(complete) completion=100
      if(completion<0) completion = 0;
      if(completion>100) completion = 100
      return completion
    }

    getCurrentWordIndex(){
      const tick = this.state.ticki
      const word_ix = Math.floor(tick / ticks_per_word)
      return word_ix
    }

    displayStaticTokens(type){
      let t = this.state.translation
      if(!t) return (<div></div>)
      if(!this.state.developmentMode){
        if(type=="duxbury") return t['human']
        else return t[type]
      }

      const tick = this.state.ticki
      const word_ix = Math.floor(tick / ticks_per_word)
      let toks = t.tokens
      if(!toks) return (<div></div>)
      
      let tokenKeys = Object.keys(toks)
      let countOfWordsToDisplay = word_ix+1
      if(this.state.show_all_output){
        countOfWordsToDisplay = tokenKeys.length
      }

      let output = tokenKeys.slice(0, countOfWordsToDisplay).map(i=>{
        let key = tokenKeys[i]
        let tok = toks[key]
        let translation = tok[type]
        let backtranslation = tok.token // tok[type + "_backtranslation"]

        let completion = this.getCompletionForWord(i)       

        return (<Word 
          key={i} 
          translation={translation} 
          completion={completion} 
          type={"reference"} 
          word={backtranslation}
          token={tok}
          accuracy={100}
          ></Word>)
      })
      return output
    }

    /** Displays the output words that were translated */
    displayWords(){
      let t = this.state.translation
      if(!t) return (<div></div>)
	      if(!this.state.developmentMode && !this.state.editEnabled){
        let translationView = this.state.translationView
        let output = t.ml
        if(translationView) output = translationView
        
        //translationAreaRef
        return (<div className="translationOutputContainer">
          <TextareaAutosize id="textOutput" disabled className="translationOutputArea" ref={(elem) => this.translationAreaRef = elem } value={output}         
          />
        </div>)
      }
      const tick = this.state.ticki
      
      let toks = t.tokens
      if(!toks) return (<div></div>)
      let tokenKeys = Object.keys(toks)
      const word_ix = Math.floor(tick / ticks_per_word)
      let countOfWordsToDisplay = word_ix+1
      if(this.state.show_all_output){
        countOfWordsToDisplay = tokenKeys.length
      }

      if(word_ix<tokenKeys.length && this.onWordOp){
        this.onWordOp({
          index: word_ix,
          token: toks[tokenKeys[word_ix]],
          completion: this.getCompletionForWord(word_ix)
        })
      }      
      let output = tokenKeys.slice(0, countOfWordsToDisplay).map(i => {
        let completion = this.getCompletionForWord(i)        
        let key = tokenKeys[i]
        let tok = toks[key]
        const translation = tok.t
        let backtranslation = tok.type=='m' ? tok.backtranslation : tok.token
        
        let score = 0
        if(tok.scoreCorrected){
          score = tok.scoreCorrected*100
        }else {
          score = tok.score*100
        }

        return (<Word
          key={i} 
          translation={translation} 
          completion={completion} 
          type={"output"} 
          word={backtranslation}
          token={tok}
          accuracy={score}
          showCorrections={true}
          devMode={this.state.developmentMode}
          simpleEdit={this.state.editEnabled}></Word>)
        //return (<Word></Word>)
      });
      return output
    }
		
    updateSourceText(txt, timeSinceLastChange){
      this.setState({
        //text: txt,
        files: []
      })
    }
    
    onInputDelete(){
	    this.setState({
        text: "",
        files: []
      })
      setTimeout((()=>{
        this.translate()
      }).bind(this), 15) 
    }

    onInputStopped(lastText){
	    
			if(window.avatar && !window.parsingLink){ 
				window.avatar.Process(6); 
				console.log('avatar.process onInputStopped');
			}
      const hasBr = hasBraille(lastText)
      const crMode = this.state.languageMode
      //Check if input has braille and switch automatically.
      if(crMode == 'braille' && hasBr){
        this.setState({
          text: lastText,
          languageMode: 'english'
        })
      }else if(crMode == 'english' && !hasBr){
        this.setState({
          text: lastText,
          languageMode: 'braille'
        })
      }else{
        //Input doesn't have braille so we keep the current language mode.
        this.setState({
          text: lastText
        })  
      }
      
      setTimeout((()=>{
        this.translate()
      }).bind(this), 15)
    }
    
    export(){
    	window.avatar.Process(6);
					console.log('avatar.process export');
      apiservice.req("/translate_and_export", {
        body:{
           'text':  this.state.text, 'tolanguage': this.state.languageMode 
        }
      }).then(r=> {
	      	if(r.ok){
		    		//window.avatar.Confirm(3);
		      	return r.json()
		      }
		      window.avatar.Error(3);
		      console.log('avatar.error export');
					return r
	      }).then((r => {
		      window.avatar.Stop(false);
					console.log('avatar.stop and idle export');
					window.waitForIdle();
					
          apiservice.redirect(r.file)
        }))
    }

    converTranslationToAscii(){
    	window.avatar.Process(6);
					console.log('avatar.process converTranslationToAscii');
    	
      let text = this.state.translation
      if(!text) return
      text = text.ml
      apiservice.req("/unicode_to_ascii", {
        'body': {'text': text}
      }).then((r=>{
        if(!r.ok) {				
	        window.avatar.Error(3);
		      window.avatar.Stop(false);
					console.log('avatar.stop error and idle converTranslationToAscii');
					window.waitForIdle();
	        return
        }

        r.text().then((text)=>{
	        //window.avatar.Confirm(3);
		      window.avatar.Stop(false);
					console.log('avatar.stop and idle converTranslationToAscii');
					window.waitForIdle();
          this.setState({
            translationView: text
          })
        });
        
      }).bind(this))
    }

    translateFiles(){
    	window.avatar.Process(6);
					console.log('avatar.process translateFiles');
    	
      let files = this.state.files
      if(files.length==0) return
      let file = files[0]
      apiservice.sendFile(file, "/translate_file", {
        'ascii': this.state.asciiMode,
        'tolanguage': this.state.languageMode
      })
        .then(r=>{
          if(r.ok){
            //console.log(r)
						//window.avatar.Confirm(3);
            return r.json()
          }
	        window.avatar.Error(3);
					console.log('avatar.error translateFiles');
					return r
        }).then((r=>{
		      window.avatar.Stop(false);
					console.log('avatar.stop and idle translateFiles');
					window.waitForIdle();
					
          //console.log(r)
          //apiservice.redirect(r.file)  
          let clean_state = this.getCleanState()
          clean_state['loading'] = false
          clean_state['text'] = r.inputText
          clean_state['translation'] = {
            'id': (new Date()).getTime(),
            'ml': r.ml,
            'liblouis': r.liblouis,
            'human': r.human,
            'tokens': r.tokens,
            'stats': r.stats,
            'backtranslation': r.backtranslation
        }
          if(!this.state.developmentMode) {
          	clean_state['show_all_output'] = true
          	clean_state['paused'] = true
						clean_state['is_complete'] = true
          }
          this.setState(clean_state)
        }).bind(this))
    }

    translate(){
    	
      let hasFiles = this.state.files && this.state.files.length > 0      

      this.setState({ 'loading': true })
      let inputText = this.state.text
      
      if(hasFiles){
        return this.translateFiles()
      }
      
      return apiservice.req("/translate_tokens", {
        body:{
           'text':  inputText, 'tolanguage': this.state.languageMode, 'ascii': this.state.asciiMode
        }
      }).then(r=> {
		      if(r.ok){
		        //window.avatar.Confirm(3);
						return r.json()
					}
	        window.avatar.Error(3);
	        return r
				}).then((r => {
					window.waitForIdle();
					window.avatar.Stop(false);
					window.parsingLink = false;
					console.log('avatar.stop and idle translate');

          let clean_state = this.getCleanState()
          clean_state['loading'] = false
          clean_state['translation'] = {
              'id': (new Date()).getTime(),
              'ml': r.ml,
              'liblouis': r.liblouis,
              'human': r.human,
              'tokens': r.tokens,
              'stats': r.stats,
              'backtranslation': r.backtranslation
          }   
          if(!this.state.developmentMode) {
          	clean_state['show_all_output'] = true
          	clean_state['paused'] = true
						clean_state['is_complete'] = true
          }
          this.setState(clean_state)
        }).bind(this))
    }

    setFile(file){
	    if(file){
	      this.setState({
	        files: [file]
	      })
	    }
      this.state.isText = true
      this.state.isUpload = false
      setTimeout((function(){
        this.translate()
      }).bind(this), 100)
    }
    
    setText(text){
	    if(text){
	      this.setState({
	        files: [],
	        text: text
	      })
	    }
      setTimeout((function(){
        this.translate()
      }).bind(this), 100)
    }

    

    renderDebugInfo(){
      let word_ix = this.getCurrentWordIndex()
      let token = this.state.translation.tokens[word_ix]
      if(!token) return
      let text = token.token
      let translation = token.t
      let comstate = this.getCompletionForWord(word_ix)
      //console.log("Completion for ", text, comstate)
      return (<div style={{
        position: 'absolute',
        bottom: 90
      }}>
        <ul>
          <li>Current word: {text}</li>
          <li>Translation: {translation}</li>
          <li>Progress: {comstate.toFixed(2)}%</li>
        </ul>
      </div>)
    }

    toggleTheme(_switch){
      let toTheme = this.state.theme == 'light' ? 'dark' : 'light'
      //_switch.setState({
	    //  text: toTheme +' Mode'
      //})
      toTheme == 'light' ? window.avatar.ColorBlend(0.0) : window.avatar.ColorBlend(1.0);
      this.setState({
        theme: toTheme
      })
      if(this.props.onThemeChange) this.props.onThemeChange(toTheme)
    }

    getBookmarkJs(){
      let translatorURL = TranslatorURL
      if(translatorURL==="") translatorURL = window.location.href
      var js = 'location.href.includes("://localhost")||(location.href="' + translatorURL + '?uri="+encodeURIComponent(location.href));'
      return "javascript:" + js + ";"
    }

    addBookmark(e){
 	    e.preventDefault();
      var url = this.getBookmarkJs()
      var location = window.location
      if (window.sidebar && window.sidebar.addPanel) { // Firefox <23		
        window.sidebar.addPanel(document.title,url,'');    
      } else if(window.external && ('AddFavorite' in window.external)) { // Internet Explorer    
        window.external.AddFavorite(url,document.title);     
      } else if(window.opera && window.print || window.sidebar && ! (window.sidebar instanceof Node)) { // Opera <15 and Firefox >23
        /**
         * For Firefox <23 and Opera <15, no need for JS to add to bookmarks
         * The only thing needed is a `title` and a `rel="sidebar"`
         * To ensure that the bookmarked URL doesn't have a complementary `#` from our trigger's href
         * we force the current URL
         */
        e.target.attr('rel', 'sidebar').attr('title', document.title).attr('href', url);
        return true;      
      } else { // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D
        return false;
        alert('Your browser doesn\'t allow us to add a bookmark automatically. \nYou must drag this button to your bookmarks bar in order to add it.');
      }
      return false;
    }

    toggleListening(){
      const toVal = !this.state.isRecording
      this.setState({
        isUpload: false,
        isText: !toVal,
        isRecording: toVal
      })
      if(toVal){
        this.props.startListening()
				window.startVoice()
				this.setState({
					showRecordInfo: true
				})
				setTimeout((()=>{
					this.setState({
						showRecordInfo: false
					})
	      }).bind(this), 2000)
      }else{
        this.props.resetTranscript()
        this.props.stopListening()
				window.stopVoice()
      }
    }

    setTextMode(){
      this.setState({
        isUpload: false,
        isText: true,
        isRecording: false
      })
      this.props.resetTranscript()
      this.props.stopListening()
      window.stopVoice()
    }

    setUploadMode(){
      this.setState({
        isUpload: true,
        isText: false,
        isRecording: false
      })
      this.props.resetTranscript()
      this.props.stopListening()
      
    }

    toggleKeyboard(){
      let keyboardEnabled = this.state.keyboardEnabled
      this.setState({
        keyboardEnabled: !keyboardEnabled
      })
    }

    toggleEdit(){
      let editEnabled = this.state.editEnabled
      editEnabled = !editEnabled
      this.setState({
        editEnabled: editEnabled
      })
    }

    copyTranslation(e){
      const el = document.createElement('textarea');
      if(!this.state.translation) return;
      var str = this.state.translation.ml
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';

      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      // this.translationAreaRef.select();
      // document.execCommand('copy');
      // e.target.focus()
    }
		handleFocus (e) {
		  if (this.props.onFocus) {
		    this.props.onFocus(e);
		  }
		}
		handleBlur (e) {
		  if (this.props.onBlur) {
		    this.props.onBlur(e);
		  }
		}
		handleLogoClick(e) {
			window.location.href = TranslatorURL;
		}

    render(){
      let textToTranslate = this.state.text
      let tokens = ( this.state.translation && this.state.translation.tokens) ? this.state.translation.tokens : []
      //console.log(this.concatTokens())
      let translation_id = this.state.translation ? this.state.translation.id : 0
      let hasDebugInfo = tokens && Object.keys(tokens).length > 0;
      let theme = this.state.theme
      const dev = this.state.developmentMode
      const menuOpen = this.state.menuOpen
      const inputTypeActive = this.state.inputType
      
      if(!dev) hasDebugInfo = false
      //console.log(textToTranslate)
      //input key is translation_id
      const isRecording = this.state.isRecording
      const audioTranscript = this.props.transcript
      const isText = this.state.isText
      const isUpload = this.state.isUpload
      const editEnabled = this.state.editEnabled
      const showRecordInfo = this.state.showRecordInfo
      const removeSpeech = this.state.removeSpeech
      const loadingShow = this.state.loadingShow
      const loadingLoading = this.state.loadingLoading
      
      if(audioTranscript && isRecording){
        textToTranslate = audioTranscript
      }
      const showKeyboard = this.state.keyboardEnabled

			let btnSpeech;
			if(!removeSpeech){
				btnSpeech = <Button className={"btn btn-speech input-window-button"+ (isRecording ? " is-recording":"")} data-window-id="input-windows-speech" variant="contained" color="primary" onClick={(()=>{
                        this.toggleListening()
                      }).bind(this)}>
                        {isRecording && "Stop"}
                        {!isRecording && "Speak"}
			                </Button>;
      }
      let leftLang = "English"
      var rightLang = "UE Braille 2"
      if(this.state.languageMode=="english"){
        rightLang = "English"
        leftLang = "UE Braille 2"
      }
      //console.log("Text to translate: " , textToTranslate)
      return (
					<div id="wrapper" className={menuOpen ? "menuOpen" : ""}>
						<Row className="row-top">
	            <Col className="grid grid-side grid-left">
	            
	            </Col>
	            <Col className="grid grid-center">	
	            	<div className={"loading-bar"+(loadingShow ? " show":"")+(loadingLoading ? " load":"")}></div>
	            </Col>
	            <Col className="grid grid-side grid-right">
	            
	            </Col>
	          </Row>
            <Row className="row-main">
              <Col id="menu" className="grid grid-side grid-left" onClick={this.toggleMenu.bind(this)}>
                <nav>
                  <Button className="btn" variant="contained" color="primary" onClick={this.toggleMenu.bind(this)} >
                    {menuOpen ? "Close" : "Menu"}
                  </Button>
                </nav>
                <div className="menu-wrapper" >
                  <div className="menu-content">
                    {/*hasDebugInfo && ( this.renderDebugInfo() )*/}
                    <Switch isChecked={ false } text="Dark Mode" handleChange={this.toggleTheme.bind(this)}  />
                    { 
                      !this.props.isMobile && 
                      (<Switch isChecked={ false } text="Developer Mode" handleChange={this.toggleDevelopmentMode.bind(this)} ref={this.toggleDevSwitch} />)
                    }

                    <Switch isChecked={ false } text="Show ASCII" handleChange={this.toggleAscii.bind(this)} ref={this.toggleAscSwitch} />

                    <a className="btn-bookmark" href={this.getBookmarkJs()} onClick={this.addBookmark.bind(this)} 
                      data-offset="{'left': -20}"
                      data-tip="<p><strong>Drag this button</strong> to the bookmarks bar to<br/>be able to translate any website into braille.</p>" 
                      data-html={true}>Bookmarklet</a>
                  </div>
                  <nav className="meta">
                    <a href="https://dotincorp.com/page/tos/" target="_blank" className="btn" >Terms & Privacy</a><br/>
                    <a href="mailto:translator@dotincorp.com" className="btn" >Get in touch</a><br/>
                    <br/>
                    <p>© 2020 Dot Inc.
                    	<br/>All rights reserved.
                    </p>
                  </nav>
                </div>
                <div id="logo-2" className="logo" onClick={this.handleLogoClick.bind(this)} >
                  <img className="white" src="/assets/logo-white.svg" />
                  <img className="black" src="/assets/logo-black.svg" />
                </div>
              </Col>
              <Col className="grid grid-center" onClick={this.closeMenu.bind(this)}>
                <Row className="row-navigation" onClick={this.toggleLanguageModes.bind(this)}>
                  <Col className="col-md-6">
                    <div className="language-switch">
                      <ul>
                        <li>{leftLang}</li>
                      </ul>
                    </div>
                  </Col>
                  <Col className="col-md-6">
                    <div className="language-switch">
                      <ul>
                        <li>{rightLang}</li>
                      </ul>
                    </div>
                  </Col>
                  <div className="col-avatar">
                  </div>
                </Row>
                <Row id="input-output" className={"row-translation dev-" + dev}>
                  <Col className=" col-md-6 col-left">
                    
                    <Input 
                      key={this.state.inputKey}
                      dynamicText={()=> (audioTranscript && isRecording) && audioTranscript }
                      showKeyboard={()=> showKeyboard }
                      text={textToTranslate} 
                      devMode={dev}
                      translateInitial={this.props.translateInitial}
                      onChange={this.updateSourceText.bind(this)}
                      onInputDelete={this.onInputDelete.bind(this)}
                      onFileChange={this.setFile.bind(this)}
                      onFileUploadStart={this.fileUploadStart.bind(this)}
                      onTextChange={this.setText.bind(this)}
                      onInputStopped={this.onInputStopped.bind(this)}
                      onFocus={this.handleFocus.bind(this)}
                      onBlur={this.handleBlur.bind(this)}
                      languageMode={this.state.languageMode}
                      //ref={(input) => { this.textInput = input; }}
                      ref={this.textInputField}
                      results={{
                        completions: this.state.word_completions,
                        tokens: tokens,
                        word_ix: this.getCurrentWordIndex()
                      }} 
                    ></Input>
                    <nav>
                      <Button className={"btn btn-text input-window-button"+ (isText ? " active":"")} variant="contained" color="primary" onClick={(()=>{
                        this.setTextMode()
                      }).bind(this)}>
                        Text
                      </Button>
                      {btnSpeech}
                      { 
                        !this.props.isMobile && 
                        (<Button className={"btn btn-upload input-window-button"+ (isUpload ? " active":"")} data-window-id="input-windows-upload" variant="contained" color="primary" onClick={(()=>{
                            this.setUploadMode()
                          }).bind(this)}>
                          Upload
                        </Button>
                      )}
                      {/*<Button className={"btn btn-keyboard"+(showKeyboard ? " active":"")} variant="contained" color="primary" onClick={this.toggleKeyboard.bind(this)}>
                        Keyboard
                      </Button>*/}
                    </nav>
                    <div className="input-windows">
                      <div id="input-windows-speech" className={"input-window"+ (isRecording ? " show":"")}><div className={"content"+ (showRecordInfo ? " show":"")}>We are listening…</div></div>
                      <div id="input-windows-upload" className={"input-window"+ (isUpload ? " show":"")}><div className={"content"+ (isUpload ? " show":"")}>Drag TXT file here.</div></div>
                    </div>
                  </Col>
                  <Col className=" col-md-6 col-right">
                    <div className="output">
                      {this.displayTranslation()}
                    </div>
                    <nav className={(this.state.translation.ml == "" ? "hide":"")}>
                      {
                        document.queryCommandSupported('copy') &&
                        (<Button className="btn btn-copy output-window-button" data-window-id="output-windows-copy" variant="contained" color="primary" 
                        onClick={this.copyTranslation.bind(this)}>
                          Copy
                        </Button>)
                      }
{/*
			                <Button className="btn" variant="contained" color="primary">
			                  Share
			                </Button>
*/}
                      { 
                        !this.props.isMobile && 
                        (<Button className="btn translationBtn" variant="contained" color="primary" onClick={this.export.bind(this)}>
                          Download
                        </Button>)
                      }
                      {
                        !this.props.isMobile && !this.state.asciiMode && !dev &&
                        (<Button className={"btn-edit btn"+ (editEnabled || dev ? " active":"")} variant="contained" data-window-id="output-windows-edit" color="primary" onClick={this.toggleEdit.bind(this)}>
		                      {editEnabled && "Stop"}
		                      {!editEnabled && "Edit"}
                        </Button>)
                      }

                    </nav>
                    <div className="output-windows">
                      <div id="output-windows-copy" className="output-window">Translation copied.</div>
                      <div id="output-windows-edit" className="output-window">Type in suggestions</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="grid grid-side grid-right">
                <div id="logo-1" className="logo" onClick={this.handleLogoClick.bind(this)}>
                  <img className="white" src="/assets/logo-white.svg" />
                  <img className="black" src="/assets/logo-black.svg" />
                </div>
              </Col>
            </Row>
            <Row className="row-bottom">
              <Col className="grid grid-side grid-left"></Col>
              <Col className="grid grid-center"></Col>
              <Col className="grid grid-side grid-right"></Col>
            </Row>
            <ReactTooltip place="right" type="dark" effect="float" html={true}/>
          </div>
      )
    }

}

Output.propTypes = propTypes

export default SpeechRecognition(speechToTextOptions)(withStyles(styles)(Output))