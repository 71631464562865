//Production endpoint is the same as current host

export var ApiEndpoint = "https://dot-translation.hyperinteractive.de"
export var TranslatorURL = "https://translate.dotincorp.com"
export var MaxWords = 1000
export var ConvertapiSecret = "kk2LPgeDihQbFnU8"
export var ConvertapiToken = ""
//TranslatorURL = "https://translate.dotincorp.com"
//ApiEndpoint = "https://dot-translation.hyperinteractive.de"
//ApiEndpoint = "http://vaskovasilev.eu:5001"
//ApiEndpoint = "http://localhost:5001"
export var TestString = ""